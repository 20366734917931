import React from "react";

import Layout from "../components/Layout";

const base64url = require("base64url");

const NotFoundPage = ({ pageContext: { config }, location }) => {
  const siteConfig = config;

  const params = new URLSearchParams(location.search);
  const extraDataEncoded = params ? params.get("ExtraData") : null;
  const extraDataDecoded = extraDataEncoded
    ? base64url.decode(extraDataEncoded)
    : null;

  const extraDataDecodedSplit = extraDataDecoded
    ? extraDataDecoded.split("|")
    : [];

  return (
    <Layout
      displayJACTA={false}
      siteConfig={siteConfig || {}}
      location={location}
      description={
        extraDataDecodedSplit && extraDataDecodedSplit[4]
          ? extraDataDecodedSplit[4]
          : extraDataDecodedSplit && extraDataDecodedSplit[1]
          ? "Check out this vacancy from " + extraDataDecodedSplit[1]
          : "Check out this vacancy."
      }
      title={
        extraDataDecodedSplit &&
        extraDataDecodedSplit[0] &&
        extraDataDecodedSplit[1]
          ? extraDataDecodedSplit[0] + " | " + extraDataDecodedSplit[1]
          : extraDataDecodedSplit && extraDataDecodedSplit[0]
          ? extraDataDecodedSplit[0]
          : "Loading page..."
      }
    >
      <main id="main">
        <div className="page__header-container page__header-container--four-o-four-landing">
          <div className="container">
            <div
              className="row justify-content-center page__header page__header--landing four-o-four-landing__header page__header--four-o-four-landing"
              id="listing-top"
            >
              <div className="col-12 col-lg-8">
                <div className="loading-panel">
                  <div className="loading-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span className="loading-text">
                    Please wait... If content is not shown soon then you may
                    have the wrong URL.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
